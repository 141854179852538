<template>
  <div class="house-container">
    <div class="main-wrapper">
      <div class="house-info-wrapper">
        <p class="search-result">
          共找到<span>{{ total }}</span
          >套房屋
        </p>
        <div class="house-item-wrapper" v-for="tmp in list" :key="tmp.id">
          <div class="img" @click="doCheckDetail(tmp.houseId)">
            <img :src="tmp.coverImg" alt="" />
          </div>
          <div class="center-info">
            <p class="house-name" @click="doCheckDetail(tmp.houseId)">
              {{ tmp.title }}
            </p>
            <p>
              <i class="icon el-icon-location-outline" />{{ tmp.community }}
            </p>
            <p>
              <i class="icon el-icon-office-building" />{{
                tmp.stayFloor
              }}楼/共{{ tmp.totalFloor }}层 | {{ tmp.unitType }} |
              {{ tmp.rentArea }}²
            </p>
            <p>{{ tmp.followCount }}人关注 | {{ tmp.createTime }}</p>
          </div>
          <div class="right-info">
            <p style="cursor: pointer" @click="doLike(tmp.houseId)">
              <span
                v-if="tmp.isRecommend"
                style="font-size: 20px; color: #00c297; margin-right: 20px"
                >诚心房</span
              >
              <svg-icon
                class="icon"
                :icon-class="tmp.isFollow ? 'xihuan-a' : 'like'"
              ></svg-icon>
              <span>{{ tmp.isFollow ? "已关注" : "关注" }}</span>
            </p>
            <p v-if="type === 1" class="price">{{ tmp.sellPrice / 10000 }}万</p>
            <p v-if="type === 1" class="unit-price">
              单价{{ tmp.unitPrice }}元/平米
            </p>
            <p v-else class="price">{{ tmp.sellPrice }}元/月</p>
          </div>
        </div>
        <div class="house-pagination" v-if="list.length">
          <el-pagination
            style="margin-top: 20px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[5, 10, 15]"
            :page-size="10"
            layout="sizes, prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="recommend-weapper">
        <p class="recommend-title">推荐中介</p>
        <div
          class="recommed-item-wrapper"
          v-for="tmp in recommedList"
          :key="tmp.id"
        >
          <img class="avatar" :src="tmp.avatar" alt="" />
          <div class="info-item">
            <p class="info-label">姓名：</p>
            <span class="info-msg">{{ tmp.userName }}</span>
          </div>
          <div class="info-item">
            <p class="info-label">星级：</p>
            <el-rate class="info-msg" disabled v-model="tmp.starLv"></el-rate>
          </div>
          <div class="info-item">
            <p class="info-label">电话：</p>
            <span class="info-msg">{{ tmp.phone }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HouseContainer",
  data() {
    return {
      currentPage: 1,
    };
  },
  props: {
    type: {
      type: Number,
      default: 1,
    },
    list: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    recommedList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
  },

  methods: {
    handleSizeChange(size) {
      this.$emit("sizeChange", size);
    },
    handleCurrentChange(page) {
      this.$emit("pageChange", page);
    },
    doCheckDetail(houseId) {
      window.open(
        window.location.origin +
          `/#/house-detail?houseId=${houseId}&type=${this.type}`
      );
      // this.$router.push({ path: "house-detail", query: { houseId, type: this.type } });
    },
    doLike(houseId) {
      if (!this.token) {
        this.$message.warning("请先登录！");
        return;
      }
      this.$request
        .post("/api/house/updateCanOrAtt", { houseId })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.$emit("update");
          } else {
            this.$message.warning(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "../styles/variable.scss";
.house-container {
  .search-result {
    padding-top: 10px;
    font-size: 18px;
    font-weight: 400;
    border-top: 1px solid $defaultColor;
    span {
      font-size: 20px;
      font-weight: bold;
      color: $mainColor;
      margin: 0 5px;
    }
  }
  .main-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .house-info-wrapper {
    flex: 1;
    .house-pagination {
      height: 80px;
    }
    .house-item-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      border-bottom: 1px dashed $defaultColor;
      .img {
        cursor: pointer;
        width: 210px;
        height: 155px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .center-info,
    .right-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .center-info {
      width: 50%;
      text-align: left;
      .house-name {
        font-size: 24px;
        font-weight: bold;
        color: $mainColor;
        cursor: pointer;
      }
    }
    .right-info {
      text-align: right;
      padding-right: 40px;
      .price {
        font-size: 24px;
        font-weight: bold;
        color: $redColor;
      }
      .unit-price {
        color: $textColor;
      }
    }
    .icon {
      margin-right: 10px;
    }
  }
  .recommend-weapper {
    width: 300px;
    padding: 10px;
    border-left: 1px solid $defaultColor;
    border-top: 1px solid $defaultColor;
    .recommend-title {
      font-size: 18px;
      font-weight: bold;
    }
    .recommed-item-wrapper {
      text-align: center;
      border-bottom: 1px solid $defaultColor;
      margin-top: 20px;
      padding: 10px 20px;
      .avatar {
        width: 60px;
        height: 60px;
        margin-bottom: 15px;
      }
      .info-item {
        margin-bottom: 20px;
        text-align: left;
        .info-label {
          display: inline-block;
          text-align: left;
          font-size: 18px;
          font-weight: bold;
        }
        .info-msg {
          display: inline-block;
          margin-left: 20px;
          color: $textColor;
        }
      }
    }
  }
}
</style>
