<template>
  <div class="filter-info-container">
    <div>
      <ul class="filter-wrapper">
        <p class="filter-title">位置：</p>
        <el-checkbox-group @change="doChangeFilter" v-model="params.city" style="display: inline-block;">
          <el-checkbox v-for="tmp in location" :key="tmp" :label="tmp" />
        </el-checkbox-group>
        <!-- <el-cascader
            size="small"
            v-model="provinceCode"
            :props="{label: 'name', value: 'code'}"
            @change="doChangeDate"
            :options="areaInfo">
        </el-cascader> -->
      </ul>
      <ul class="filter-wrapper" v-if="type === 2">
        <p class="filter-title">租房方式：</p>
        <el-radio-group @change="doChangeFilter" v-model="params.rentType">
          <el-radio label="">全部</el-radio>
          <el-radio label="整租">整租</el-radio>
          <el-radio label="合租">合租</el-radio>
        </el-radio-group>
      </ul>
      <ul class="filter-wrapper" v-if="type === 2">
        <p class="filter-title">租金：</p>
        <el-checkbox-group @change="doChangeFilter" v-model="params.sellPriceRange" style="display: inline-block;">
          <el-checkbox v-for="tmp in rent" :key="tmp.id" :label="tmp.value">{{ tmp.title }}</el-checkbox>
        </el-checkbox-group>
      </ul>
      <!-- <ul class="filter-wrapper" v-if="type === 2">
          <p class="filter-title">户型：</p>
          <el-checkbox-group @change="doChangeFilter" v-model="params.bedroomRange" style="display: inline-block;">
              <el-checkbox v-for="tmp in houseType" :key="tmp.id" :label="tmp.title">{{ tmp.title }}</el-checkbox>
          </el-checkbox-group>
      </ul> -->
      <ul class="filter-wrapper" v-if="type === 2">
        <p class="filter-title">特色：</p>
        <el-checkbox-group @change="doChangeFilter" v-model="params.charaLabelRange" style="display: inline-block;">
          <el-checkbox v-for="tmp in houseFeature" :key="tmp.id" :label="tmp.title">{{ tmp.title }}</el-checkbox>
        </el-checkbox-group>
      </ul>
      <ul class="filter-wrapper no-border" v-if="type === 2">
        <p class="filter-title">租期：</p>
        <el-checkbox-group @change="doChangeFilter" v-model="params.payTypeRange" style="display: inline-block;">
          <el-checkbox v-for="tmp in houseLease" :key="tmp.id" :label="tmp.title">{{ tmp.title }}</el-checkbox>
        </el-checkbox-group>
      </ul>
      <ul class="filter-wrapper" v-if="type === 1">
        <p class="filter-title">价格：</p>
        <el-checkbox-group @change="doChangeFilter" v-model="params.sellPriceRange" style="display: inline-block;">
          <el-checkbox v-for="tmp in price" :key="tmp.id" :label="tmp.value">{{ tmp.title }}</el-checkbox>
        </el-checkbox-group>
      </ul>
      <ul class="filter-wrapper no-border" v-if="type === 1">
        <p class="filter-title">建筑面积：</p>
        <el-checkbox-group @change="doChangeFilter" v-model="params.rentAreaRange" style="display: inline-block;">
          <el-checkbox v-for="tmp in area" :key="tmp.id" :label="tmp.value">{{ tmp.title }}</el-checkbox>
        </el-checkbox-group>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterInfo",
  props: {
    type: {
      type: Number,
      default: 1
    }
  },
  mounted () {
    this.doFindAreaInfo()
  },
  methods: {
    doChangeFilter () {
      this.$emit('change', this.params)
    },
    doChangeDate (val) {
      if (val.length === 3) {
        this.params.province = [0]
        this.params.city = [1]
        this.params.area = [2]
      }
    },
    doFindAreaInfo () {
      this.$request.get('/api/house/getAreaList').then(res => {
        if (res.code === 200) {
          this.areaInfo = res.data
        } else {
          this.areaInfo = []
        }
      })
    }
  },
  data() {
    return {
      params: {
        areaInfo: '',
        bedroomRange: [],
        charaLabelRange: [],
        city: [],
        rentType: '',
        houseType: 1,
        isRecommend: null,
        payTypeRange: [],
        rentAreaRange: [],
        sellPriceRange: [],
        sortType: 1
      },
      areaInfo: [],
      provinceCode: '',
      priceList: [],
      areaList: [],
      rentPrice: [],
      location: ["鼓楼", "建邺", "秦淮", "玄武", "雨花台", "栖霞", "江宁", "浦口", "六合", "溧水", "高淳"],
      price: [
        { id: 1, title: "80万以下", value: '0-800000' },
        { id: 2, title: "80-100万", value: '800000-1000000' },
        { id: 3, title: "100-150万", value: '1000000-1500000' },
        { id: 4, title: "150-200万", value: '1500000-2000000' },
        { id: 5, title: "200-300万", value: '2000000-3000000' },
        { id: 6, title: "300-500万", value: '3000000-5000000' }
      ],
      area: [
        { id: 1, title: "60m²以下", value: '0-60' },
        { id: 2, title: "60-90m²", value: '60-90' },
        { id: 3, title: "90-100m²", value: '90-100' },
        { id: 4, title: "110-130m²", value: '110-120' },
        { id: 5, title: "130-150m²", value: '130-150' },
        { id: 6, title: "150-200m²", value: '150-200' }
      ],
      rent: [
        { id: 1, title: "<1000元", value: '0-1000' },
        { id: 2, title: "1000-1500元", value: '1000-1500' },
        { id: 3, title: "1500-2000元", value: '1500-2000' },
        { id: 4, title: "2000-3000元", value: '2000-3000' },
        { id: 5, title: ">3000元", value: '3000-999999999' }
      ],
      houseType: [
        { id: 1, title: "一居" },
        { id: 2, title: "两居" },
        { id: 3, title: "三居" },
        { id: 4, title: "四居" },
        { id: 5, title: "四居+" }
      ],
      houseFeature: [
        { id: 1, title: "独卫" },
        { id: 2, title: "近地铁" },
        { id: 3, title: "拎包入住" },
        { id: 4, title: "独立阳台" },
        { id: 5, title: "精装修" },
        { id: 6, title: "押一付一" }
      ],
      houseLease: [
        { id: 1, title: "月租" },
        { id: 2, title: "年租" },
        { id: 3, title: "一个月起租" },
        { id: 4, title: "1-3个月" },
        { id: 5, title: "4-6个月" }
      ]
    };
  }
};
</script>

<style lang="scss">
@import "../styles/variable.scss";
.filter-info-container {
  padding: 10px;
  border: 1px solid $defaultColor;
  .filter-wrapper {
    padding: 10px 0;
    border-bottom: 1px dashed $defaultColor;
    li {
      cursor: pointer;
      display: inline-block;
      margin: 0 10px;
    }
  }
  .filter-title {
    min-width: 80px;
    text-align: right;
    display: inline-block;
    font-weight: 400;
    color: $mainColor;
  }
  .no-border {
    border: none;
  }
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: $mainColor;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: $mainColor;
    border-color: $mainColor;
  }
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: $mainColor;
  }
  .el-radio__input.is-checked .el-radio__inner {
    background-color: $mainColor;
    border-color: $mainColor;
  }
  .el-radio__input.is-checked+.el-radio__label {
    color: $mainColor;
  }
}
</style>
